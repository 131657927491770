import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { rolessingle, rolescreate, rolesupdate } from '../../actions';
import { errorRender, successRedriect, validate, getValue, inputCheck, redirectList } from '../../helpers/functions';

const Inputfield = lazy(() => import('../../components/inputfields'));
const Buttons = lazy(() => import('../../components/buttons'));
const Links = lazy(() => import('../../components/links'));
const Loader = lazy(() => import('../../components/loader'));

class RoleCreation extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        modules: [
            { name: 'dashboard', list: false, create: false, update: false, delete: false, view: false, disableList: ['create', 'update', 'delete', 'view'] },
            { name: 'zone', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'region', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'territory', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'beat', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },   
            { name: 'users', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'customers', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'indirectCustomers', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'srASM', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'brand', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'product', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'uom', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'stock', list: false, create: false, update: false, delete: false, view: false, disableList: ['create', 'delete', 'view'] },
            { name: 'agency', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'transporter', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'scheme', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'outstanding', list: false, create: false, update: false, delete: false, view: false, disableList: ['create', 'update', 'delete', 'view'] },
            { name: 'dispatchDetails', list: false, create: false, update: false, delete: false, view: false, disableList: ['create', 'update', 'delete', 'view'] },
            { name: 'order', list: false, create: false, update: false, delete: false, view: false, disableList: [] },
            { name: 'routePlanning', list: false, create: false, update: false, delete: false, view: false, disableList: [] },
            { name: 'categoryExpense', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'subCategoryExpense', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'expenseApprovalExpense', list: false, create: false, update: false, delete: false, view: false, disableList: ['create', 'delete'] },
            { name: 'advanceApproval', list: false, create: false, update: false, delete: false, view: false, disableList: ['create', 'delete'] },
            { name: 'userRetailers', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: "trending", list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'categoriesRetailers', list: false, create: false, update: false, delete: false, view: false, disableList: ['view'] },
            { name: 'ecatalogue', list: false, create: false, update: false, delete: false, view: false, disableList: [] },
            { name: "orderRetailers", list: false, create: false, update: false, delete: false, view: false, disableList: ['create', 'delete'] },
            { name: 'payment', list: false, create: false, update: false, delete: false, view: false, disableList: ['create', 'update', 'delete'] },
            { name: 'feedback', list: false, create: false, update: false, delete: false, view: false, disableList: ['create', 'update', 'delete'] },
            { name: 'profile', list: false, create: false, update: false, delete: false, view: false, disableList: ['create', 'delete', 'view'] },
        ],
        tableHeader:[
            { name: 'list', displayName: 'List', checked: false },
            { name: 'create', displayName: 'Create', checked: false },
            { name: 'update', displayName: 'Update', checked: false },
            { name: 'delete', displayName: 'Delete', checked: false },
            { name: 'view', displayName: 'View', checked: false },
        ],
        name: '',
        code: '',
        serverErrors: ["roles_create_res", "roles_update_res", "roles_single_res"],
        forRedirect: [
            { name: "roles_create_res", pageName: 'Roles', pageUrl: 'role', action: "Added" },
            { name: "roles_update_res", pageName: 'Roles', pageUrl: 'role', action: "Updated" }
        ],
        setFields: [
            { name: "roleName", stateName: "name", type: "text" },
            { name: "roleCode", stateName: "code", type: "text" },
        ],
        nameError: '',
        codeError: "",
        isLoading: false,
        getSingleLoader: false,
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true })
            this.props.rolessingle(id);
        }
    }
    
    handleMasterCheckboxChange = (key) => {
        const newValue = !this.state.tableHeader.find(checkbox => checkbox.name === key).checked;
        const modules = this.state.modules.map((module) => {
            const updatedModule = { ...module };
            if (!updatedModule.disableList.includes(key)) {
              updatedModule[key] = newValue;
            };

            const isChecked = ['list', 'create', 'update', 'delete', 'view'].every((field) => updatedModule[field] || updatedModule.disableList.includes(field));
            updatedModule.isChecked = isChecked;

            return updatedModule;
        });
        const tableHeader = this.state.tableHeader.map(checkbox =>
            checkbox.name === key ? { ...checkbox, checked: newValue } : checkbox
        );
        this.setState({
            modules: modules,
            tableHeader: tableHeader,
        });
    };

    getLabel = (match) => {
        const item = redirectList.find((item) => item.match === match);
        return item ? item.label : null;
    };

    handleCheckboxChange = (index, key) => {
        const modules = [...this.state.modules];
        modules[index][key] = !modules[index][key];

        const fieldsToCheck = ['list', 'create', 'update', 'delete', 'view'].filter((item) => !modules[index].disableList.includes(item));
        const isChecked = fieldsToCheck.every((item) => modules[index][item]);

        modules[index].isChecked = isChecked;

        const tableHeader = this.state.tableHeader.map(headerCheckbox => {
            const anyChecked = modules.every(module => module.disableList.includes(headerCheckbox.name) || module[headerCheckbox.name]);
            return { ...headerCheckbox, checked: anyChecked };
        });
    
        this.setState({
            modules: modules,
            tableHeader: tableHeader
        });
    }

    componentWillUnmount() {
        this.setState(this.state);
    }

    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value, "Roles");
        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
    }

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 100, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "codeError" },
            
        ];
        const updatedModules = this.state.modules.map(({ disableList, isChecked, ...rest }) => rest);

        let data = {
            roleName: this.state.name,
            roleCode: this.state.code,
            permission: updatedModules,
        };
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.forEach((el) => this.setState({ [el.errName]: el.message }));
        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.rolesupdate(data, this.state.itemId);
            } else {
                this.props.rolescreate(data);
            }
            validationFields = null;
            newArr = null;
        }
    }

    handleSelectAllChange = (index) => {
        const isChecked = !this.state.modules[index].isChecked;
        const modules = this.state.modules.map((module, i) => {
            if (index === i) {
                let updatedModule = { ...module, isChecked: isChecked };
                
                ['list', 'create', 'update', 'delete', 'view'].forEach((item) => {
                    if (!module.disableList.includes(item)) {
                      updatedModule[item] = isChecked;
                    };
                  });

                return updatedModule;
            }
            return module;
        });
        const tableHeader = this.state.tableHeader.map(checkbox => {
            const allChecked = modules.every(module =>  module.disableList.includes(checkbox.name) || module[checkbox.name]);
            return { ...checkbox, checked: allChecked };
        });
      
        this.setState({
            modules: modules,
            tableHeader: tableHeader
          });
       
    };


    updateTableHeaderCheckboxes = (permissions) => {

        const modules = this.state.modules.map((module) => {
            const permission = permissions.find((perm) => perm.name === module.name);
            if (permission) {
                module.list = permission.list;
                module.create = permission.create;
                module.update = permission.update;
                module.delete = permission.delete;
                module.view = permission.view;
            }
            
            const fieldsToCheck = ['list', 'create', 'update', 'delete', 'view'].filter(
                (item) => !module.disableList.includes(item)
            );
            const isChecked = fieldsToCheck.every((item) => module[item]);
            module.isChecked = isChecked;          
            return module;
        });
        const tableHeader = this.state.tableHeader.map(checkbox => {
            const allChecked = modules.every(module => module.disableList.includes(checkbox.name) || module[checkbox.name]);
            return { ...checkbox, checked: allChecked };
        });
        this.setState({ 
            tableHeader: tableHeader,
            modules: modules
        });
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
          if (this.state.isLoading) {
            this.setState({ isLoading: false });
          }
          this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
          this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.roles_single_res && nextProps.roles_single_res.success) {
              this.setState({ getSingleLoader: false });
                if (nextProps.roles_single_res.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.roles_single_res.item, value.name) });
                        } 
                    });
                     this.updateTableHeaderCheckboxes(nextProps.roles_single_res.item.permission);
                    nextProps.roles_single_res.success = null;
                };
            };
        }
        return true;
      }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        {
                                            pageType === "update" ? "Update Role" : "Add Role"
                                        }
                                    </div>
                                </div>
                                {
                                    getSingleLoader ? <Loader /> :
                                        <div className="card-body">
                                            <div className="row">

                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="nameHelp" placeholder="Role Name" />
                                                </div>

                                                <div className="form-group required col-12 col-lg-4">
                                                    <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} maxlength="100" type="text" name="code" value={this.state.code} className="form-control" id="code" aria-describedby="emailHelp" placeholder="Role Code" />
                                                </div>

                                            </div>
                                        </div>
                                }
                                {getSingleLoader ? null :  
                                <> 
                                    <div className="col-md-12 mt-4 mb-2">
                                        <h5>Permissions</h5>
                                    </div>
                                    <div
                                        class="table table-hover col-md-12"
                                    >
                                        <table
                                            class="table table-Secondary"
                                        >
                                            <thead>
                                            <tr>
                                                <th>
                                                    <span className='p-2'>
                                                        Module List
                                                    </span>
                                                </th>
                                                {this.state.tableHeader.map((items)=>(
                                                    <th key={items.name}>
                                                        <input
                                                        type="checkbox"
                                                        checked={items.checked}
                                                        onChange={() => this.handleMasterCheckboxChange(items.name)}
                                                        />
                                                        <span className='p-2'>
                                                            {items.displayName}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.modules.map((module, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                             <input
                                                                type="checkbox"
                                                                className='ms-2'
                                                                checked={module.isChecked}
                                                                onChange={()=>this.handleSelectAllChange(index)}
                                                            />
                                                            <span className='p-2'>
                                                                {this.getLabel(module.name)}
                                                            </span>
                                                            </td>
                                                            {this.state.tableHeader.map(checkbox => (
                                                                <td key={checkbox.name}>
                                                                    <input
                                                                    type="checkbox"
                                                                    checked={module[checkbox.name]}
                                                                    disabled={module.disableList.includes(checkbox.name)}
                                                                    onChange={() => this.handleCheckboxChange(index, checkbox.name)}
                                                                    />
                                                                </td>
                                                            ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.role} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>

                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        roles_create_res: state.roles_create_res,
        roles_single_res: state.roles_single_res,
        roles_update_res: state.roles_update_res,
    };
}


const mapDispatchToProps = dispatch => ({
    rolescreate: data => dispatch(rolescreate(data)),
    rolessingle: data => dispatch(rolessingle(data)),
    rolesupdate: (data, id) => dispatch(rolesupdate(data, id)),
});

RoleCreation = connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleCreation);

export default RoleCreation;