import { apiUrl } from '../config';
import { routehelp } from '../Routehelper';



const post = async (url, payload) =>
  await fetch(apiUrl + url, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload | {})
  })
    .then(res => res.json())
    .then(res => res)
    .catch(error => error);
const getToken = () => {
  return localStorage.getItem('token');
};
const postWithPayload = async (url, payload) =>
  await fetch(apiUrl + url, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then(res => res.json())
    .then(res => res)
    .catch(error => error);

const postWithPayloadwithToken = async (url, payload) =>
  await fetch(apiUrl + url, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': getToken()
    },
    body: JSON.stringify(payload)
  })
    .then(res => {
      if(res.status === 401){
        localStorage.clear();
        res.errors = undefined
        window.location.replace("/");
      } else if (res.status === 403) {
        localStorage.setItem("redirect", "yes")
        window.location.replace(routehelp.maindashboard);
      }
      return res.json();
    })
    .catch(function (error) {
      console.log('There has been a problem with your fetch operation: ',
        error.message);
      return error;
    });

const getById = async (url, payload) =>
  await fetch(apiUrl + url + payload, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({})
  })
    .then(res => res.json())
    .then(res => res)
    .catch(error => error);


const postWithPayloadwithTokenandmultipart = async (url, payload) =>
  await fetch(apiUrl + url, {
    method: 'post',
    headers: {
      'x-access-token': getToken()
    },
    body: payload
  })
    .then(res => res.json())
    .then(res => res)
    .catch(error => error);


export const api = {
  post,
  postWithPayload,
  getById,
  postWithPayloadwithToken,
  getToken,
  postWithPayloadwithTokenandmultipart
};
