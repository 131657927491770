import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import { routehelp } from '../../Routehelper';
import { stocksingle, stockmanagementupdate } from '../../actions';
import { errorRender, successRedriect, getValue } from '../../helpers/functions';

const Links = lazy(() => import('../../components/links'));
const Buttons = lazy(() => import('../../components/buttons'));
const Inputfield = lazy(() => import('../../components/inputfields'));
const Loader = lazy(() => import('../../components/loader'));

class StockUpdate extends Component {
    state = {
        pageType: 'update',
        itemId: '',
        name: '',
        brand: '',
        serverError: ["stock_update", "stocksingle_res"],
        stockAttributes: [],
        clsStockAttributes: [],
        forwardStockAttributes: [],
        forRedirect: [
            // { name: "product_create_res", pageName: 'Product', pageUrl: 'productmanagement', action: "Added" },
            { name: "stock_update", pageName: 'Stock', pageUrl: 'stockmanagement', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "brand", stateName: "brand", type: "text" },
            { name: "Stock Attributes", stateName: "stockAttributes", type: "array" },
            { name: "Cls StockAttributes", stateName: "clsStockAttributes", type: "array" },
            { name: "forward StockAttributes", stateName: "forwardStockAttributes", type: "array" },
        ],
        isLoading: false,
        getSingleLoader: false,
        data: {}
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        // console.log(id);
        if (id) {
            this.setState({ pageType: "update", itemId: id, getSingleLoader: true });
            this.props.stocksingle(id);
        }
    }

    onChange = (e, id, shade) => {

        // console.log("index", id);
        // console.log(e.target.value);

        e.target.value = parseFloat(e.target.value);

        if (e.target.value < 0) {
            alert("Please fill valid value of stock quantity");
            e.target.value = 0;
        }

        let tempStockArray = this.state.stockAttributes;

        if (tempStockArray && tempStockArray.length > 0) {
            tempStockArray.forEach((ele, index) => {
                if (index === id) {
                    if (JSON.stringify(shade) === JSON.stringify(ele.name)) {
                        ele.quantity = e.target.value;
                    }
                }
            });
        }

        this.setState({
            stockAttributes: tempStockArray
        });

        // console.log(this.state.stockAttributes);
    }

    clsOnChange = (e, id, shade) => {

        // console.log("index", id);
        // console.log(e.target.value);

        e.target.value = parseFloat(e.target.value);

        if (e.target.value < 0) {
            alert("Please fill valid value of stock quantity");
            e.target.value = 0;
        }

        let tempStockArray = this.state.clsStockAttributes;

        if (tempStockArray && tempStockArray.length > 0) {
            tempStockArray.forEach((ele, index) => {
                if (index === id) {
                    if (JSON.stringify(shade) === JSON.stringify(ele.name)) {
                        ele.quantity = e.target.value;
                    }
                }
            });
        }

        this.setState({
            clsStockAttributes: tempStockArray
        });
    }

    forwardOnChange = (e, id, shade) => {

        e.target.value = parseFloat(e.target.value);

        if (e.target.value < 0) {
            alert("Please fill valid value of stock quantity");
            e.target.value = 0;
        }

        let tempStockArray = this.state.forwardStockAttributes;

        if (tempStockArray && tempStockArray.length > 0) {
            tempStockArray.forEach((ele, index) => {
                if (index === id) {
                    if (JSON.stringify(shade) === JSON.stringify(ele.name)) {
                        ele.quantity = e.target.value;
                    }
                }
            });
        }

        this.setState({
            forwardStockAttributes: tempStockArray
        });

        // console.log(this.state.clsStockAttributes);
    }

    onsubmit = (e) => {
        let data = this.state.data;

        if (this.state.stockAttributes && this.state.stockAttributes.length > 0) {

            this.state.stockAttributes.forEach((ele) => {
                if (!ele.quantity) {
                    ele.quantity = 0;
                }
            });

            this.setState({
                stockAttributes: this.state.stockAttributes
            });

        }

        if (this.state.clsStockAttributes && this.state.clsStockAttributes.length > 0) {

            this.state.clsStockAttributes.forEach((ele) => {
                if (!ele.quantity) {
                    ele.quantity = 0;
                }
            });

            this.setState({
                clsStockAttributes: this.state.clsStockAttributes
            });

        }

        if (this.state.forwardStockAttributes && this.state.forwardStockAttributes.length > 0) {

            this.state.forwardStockAttributes.forEach((ele) => {
                if (!ele.quantity) {
                    ele.quantity = 0;
                }
            });

            this.setState({
                forwardStockAttributes: this.state.forwardStockAttributes
            });

        }

        let isMainFw = false;
        if (this.state.forwardStockAttributes && this.state.forwardStockAttributes.length > 0) {
            this.state.forwardStockAttributes.forEach((ele, ind) => {
                if (+ele.quantity > 0 && +this.state.stockAttributes[ind].quantity > 0) {
                    isMainFw = true;
                }
            });
        };

        data.stockAttributes = this.state.stockAttributes;
        data.clsStockAttributes = this.state.clsStockAttributes;
        data.forwardStockAttributes = this.state.forwardStockAttributes;

        data.updateType = "stockUpdate";

        e.preventDefault();

        if (isMainFw) {
            alert(`Please check main and forward stock quantity. only one stock is applicable to upload.`);
        } else {
            this.props.stockmanagementupdate(data, this.state.itemId);
        }
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            };
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.stocksingle_res && nextProps.stocksingle_res.success) {
                nextProps.stocksingle_res.success = null;
                this.setState({ getSingleLoader: false });
                if (nextProps.stocksingle_res.item) {

                    this.setState({
                        data: nextProps.stocksingle_res.item
                    });

                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.stocksingle_res.item, value.name) })
                        }

                        if (value.type === "array") {
                            this.setState({ [value.stateName]: nextProps.stocksingle_res.item[value.stateName] })
                        }

                    });
                }

            };
        }
        return true;
    }

    render() {
        const { pageType, isLoading, getSingleLoader } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Stock Update
                                    </div>
                                </div>
                                {
                                    getSingleLoader ? <Loader /> :
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield disabled={true} maxlength="100" type="text" name="name" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Product Name" />
                                                </div>
                                                <div className="form-group col-12 col-lg-4">
                                                    <Inputfield disabled={true} maxlength="100" type="text" name="name" value={this.state.brand.name} className="form-control" id="brand" ariadescribedby="codeHelp" placeholder="Brand Name" />
                                                </div>
                                            </div>
                                            {/* {console.log(this.state.stockAttributes)} */}
                                            <label className='form-group mt-3'>Stock Details</label>
                                            <div className='row'>
                                                <div className='form-group col-9 col-lg-3'>
                                                    <div className='alert alert-info font-weight-bold' disabled>Name</div>
                                                </div>
                                                <div className='form-group col-9 col-lg-3'>
                                                    <div className='alert alert-info font-weight-bold' disabled>Main Quantity</div>
                                                </div>
                                                <div className='form-group col-9 col-lg-3'>
                                                    <div className='alert alert-info font-weight-bold' disabled>CLS Quantity</div>
                                                </div>
                                                <div className='form-group col-9 col-lg-3'>
                                                    <div className='alert alert-info font-weight-bold' disabled>Forward Quantity</div>
                                                </div>
                                            </div>
                                            {
                                                this.state.stockAttributes && this.state.stockAttributes.length > 0 ?
                                                    this.state.stockAttributes.map((ele, index) =>
                                                        <div className='row' key={index}>

                                                            <div className='form-group col-9 col-lg-3'>
                                                                <div className='form-control'>{ele.name}</div>
                                                            </div>
                                                            <div className='form-group col-9 col-lg-3'>
                                                                <input type="number" name={"quantity-" + ele._id} value={ele.quantity} className='form-control' onChange={(e) => this.onChange(e, index, ele.name)} />
                                                            </div>
                                                            <div className='form-group col-9 col-lg-3'>
                                                                {
                                                                    this.state.clsStockAttributes && this.state.clsStockAttributes.length > 0 ? this.state.clsStockAttributes.map((clsstock, ind) => {
                                                                        if (JSON.stringify(clsstock.name) === JSON.stringify((ele.name))) {
                                                                            return (
                                                                                <input type="number" key={clsstock._id} name={"quantity-" + clsstock._id} value={clsstock.quantity} className='form-control' onChange={(e) => this.clsOnChange(e, ind, clsstock.name)} />
                                                                            )
                                                                        }
                                                                    }) : <span>{0}</span>
                                                                }
                                                            </div>
                                                            <div className='form-group col-9 col-lg-3'>
                                                                {
                                                                    this.state.forwardStockAttributes && this.state.forwardStockAttributes.length > 0 ? this.state.forwardStockAttributes.map((forwardstock, ind) => {
                                                                        if (JSON.stringify(forwardstock.name) === JSON.stringify((ele.name))) {
                                                                            return (

                                                                                <input type="number" key={forwardstock._id} name={"quantity-" + forwardstock._id} value={forwardstock.quantity} className='form-control' onChange={(e) => this.forwardOnChange(e, ind, forwardstock.name)} />
                                                                            )
                                                                        }
                                                                    }) : <span>{0}</span>
                                                                }
                                                            </div>
                                                        </div>

                                                    ) : <div className='text-center text-danger'>No Data is Available</div>
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <Buttons onclick={this.onsubmit} type="submit" id="submit" className="btn btn-primary mr-1" disable={isLoading || getSingleLoader} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                        <Links to={routehelp.stockmanagement} className="btn btn-outline-dark" name="Cancel" />
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        // uommanagement_res: state.uommanagement_res,
        // product_create_res: state.product_create_res,
        stocksingle_res: state.stocksingle_res,
        stock_update: state.stock_update
    };
}

const mapDispatchToProps = dispatch => ({
    // uommanagementlist: data => dispatch(uommanagementlist(data)),
    // productmanagecreate: data => dispatch(productmanagecreate(data)),
    stocksingle: data => dispatch(stocksingle(data)),
    stockmanagementupdate: (data, id) => dispatch(stockmanagementupdate(data, id))
});

StockUpdate = connect(
    mapStateToProps,
    mapDispatchToProps
)(StockUpdate);

export default StockUpdate;