import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import Select from 'react-select';
import { catalogueCreate, productdropdownlist, cataloguesingle, catalogueupdate } from '../../actions';
import ProductDropdown from '../productManagement/productDropdown';
import ShadeDropDown from '../catalogue/shadeDropdown';
import { uploadUrl, apiUrl } from '../../config';
import CategoryDropdown from '../catagory/categoryDropdown';
import SingleBrandDropDown from '../brandsManagement/singleBrandDropDown';
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { errorRender, successRedriect, validate, getValue } from '../../helpers/functions';
import TextAreaInputField from '../../components/textAreaInputField';
import ErrorView from '../../components/errorView';
import toastr from "reactjs-toastr";
import Translate from '../../libs/translation';

const CardTitle = lazy(() => import('../../components/cardTitle'));


class CatalogueAdd extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        category: '',
        brand: '',
        product: '',
        shade: '',
        shadeOptions: [],
        description: '',
        options: [],
        uploadPercentage: 0,
        documents: [],
        serverErrors: ["catalogue_create_res", "catalogue_single", "catalogue_update"],
        forRedirect: [
            { name: "catalogue_create_res", pageName: 'Catalogue', pageUrl: 'eCatalogue', action: "Added" },
            { name: "catalogue_update", pageName: 'Catalogue', pageUrl: 'eCatalogue', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "category", stateName: "category", type: "select" },
            { name: "brand", stateName: "brand", type: "select" },
            { name: "product", stateName: "product", type: "select" },
            { name: "shade", stateName: "shade", type: "select" },
            { name: "description", stateName: "description", type: "text" },
            { name: "img", stateName: "documents", type: "text" },
        ],
        nameError: '',
        catagoryError: '',
        brandError: '',
        productError: '',
        shadeError: '',
        descriptionError: '',
        documentError: '',
        isLoading: false,
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id })
            this.props.cataloguesingle(id);
        }
    }

    dropdownValueChange = (value, name) => {
        if (name === "brand") {
            this.setState({ [name]: value, product: null, shade: null, shadeOptions: [] });
            let data = {};
            data.limit = 50000;
            data.filter = { brand: value.value };
            this.props.productdropdownlist(data);
        } else if (name === "product") {
            this.setState({
                [name]: {
                    label: value.label,
                    value: value.value
                }, shade: null
            });
            if (value.items && value.items.attributes && value.items.attributes.length > 0) {
                let data = [];
                for (let i = 0; i < value.items.attributes.length; i++) {
                    if (value.items.attributes[i].name !== "") {
                        data.push({ value: value.items.attributes[i].name, label: value.items.attributes[i].name })
                    }
                }
                this.setState({ shadeOptions: data })
            }
        }
        else {
            this.setState({ [name]: value })
        }
    }

    uploadFile = ({ target: { files } }) => {

        if (files && files.length > 0 && files[0].size && files[0].size > 20971520) //20mb
        {
            toastr.error(Translate.translate("", 'error.message.image_size'), 'Error', {
                displayDuration: 5000
            });
            files = [];
            return true;
        }

        let data = new FormData();
        data.append('file', files[0]);
        const token = localStorage.getItem('token');
        const options = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total);
                // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

                if (percent < 100) {
                    this.setState({ uploadPercentage: percent });
                }
            }
        };

        axios.defaults.headers = { 'x-access-token': token };
        axios.post(apiUrl + '/api/catalogues/upload', data, options)
            .then(res => {
                this.state.documents.push(
                    res.data.item.img
                );
                document.getElementById('myFile').value = '';
                this.setState({ uploadPercentage: 100 }, () => {
                    setTimeout(() => {
                        this.setState({ uploadPercentage: 0 });
                    }, 1000);
                });
            });

    };

    handleChange = (name, e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [name]: e.target.value });
        }
    }

    onsubmit = (e) => {
        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 100, type: "text", message: "", errName: "nameError" },
            { name: 'category', label: "Category", value: this.state.category, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "catagoryError" },
            { name: 'brand', label: "Brand", value: this.state.brand, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "brandError" },
            { name: 'product', label: "Product", value: this.state.product, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "productError" },
            { name: 'shade', label: "Shade", value: this.state.shade, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "shadeError" },
            { name: 'description', label: "Description", value: this.state.description, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "descriptionError" },
            { name: 'documents', label: "Image", value: this.state.documents, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "text", message: "", errName: "documentError" }
        ]
        let data = {
            name: this.state.name,
            brand: this.state.brand && this.state.brand.value,
            description: this.state.description,
            product: this.state.product && this.state.product.value,
            shade: this.state.shade && this.state.shade.value,
            category: this.state.category && this.state.category.value,
            img: this.state.documents,
            isActive: true
        }
        e.preventDefault();
        let { newArr, valid } = validate(validationFields);
        newArr.forEach((el) => this.setState({ [el.errName]: el.message }));
        if (valid) {
            this.setState({ isLoading: true });
            if (this.state.pageType === "update") {
                this.props.catalogueupdate(data, this.state.itemId);
            } else {
                this.props.catalogueCreate(data);
            }
            validationFields = null;
            newArr = null;
        }
    }

    removeImage = (index) => {
        this.setState({
            documents: this.state.documents.filter((s, sidx) => index !== sidx)
        });
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverErrors.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.catalogue_single && nextProps.catalogue_single.success) {
                nextProps.catalogue_single.success = null;
                if (nextProps.catalogue_single.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.catalogue_single.item, value.name) })
                        } else if (value.type === "select" && getValue(nextProps.catalogue_single.item, value.name)) {
                            let obj = {
                                label: getValue(nextProps.catalogue_single.item, value.name).name ? getValue(nextProps.catalogue_single.item, value.name).name : getValue(nextProps.catalogue_single.item, value.name),
                                value: getValue(nextProps.catalogue_single.item, value.name)._id ? getValue(nextProps.catalogue_single.item, value.name)._id : getValue(nextProps.catalogue_single.item, value.name)
                            }
                            this.dropdownValueChange(obj, value.name);
                            obj = null
                        }
                    })
                }
            }
        }
        return true;
    }

    render() {
        const { uploadPercentage, pageType, isLoading } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <CardTitle title={pageType === "update" ? "Update E-Catalogue" : "Add E-Catalogue"} />
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group required col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange}
                                                type="text" name="name" maxlength="100" value={this.state.name}
                                                className="form-control" id="name"
                                                ariadescribedby="codeHelp" placeholder="Name" />
                                            {/* {console.log(this.state.name.length || 0)} */}
                                        </div>
                                        <div className="col-md-6 form-group required col-12 col-lg-4">
                                            <CategoryDropdown
                                                isLabel={true}
                                                ondropdownChange={(e) => this.dropdownValueChange(e, "category")}
                                                placeholder="Search category"
                                                value={this.state.category}
                                                errMessage={this.state.catagoryError}
                                            />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <SingleBrandDropDown
                                                ondropdownChange={(e) => this.dropdownValueChange(e, "brand")}
                                                placeholder="Search Brand"
                                                value={this.state.brand}
                                                errMessage={this.state.brandError}
                                            />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <ProductDropdown
                                                isLabel={true}
                                                dontPreload={pageType === "update"}
                                                ondropdownChange={(e) => this.dropdownValueChange(e, "product")}
                                                placeholder="Search Product"
                                                value={this.state.product}
                                                errMessage={this.state.productError}
                                            />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            {
                                                pageType === "update" && this.state.shade ?
                                                    <ShadeDropDown
                                                        dontPreload={pageType === "update"}
                                                        ondropdownChange={(e) => this.dropdownValueChange(e, "shade")}
                                                        placeholder="Search Shade"
                                                        value={this.state.shade}
                                                        productValue={this.state.product}
                                                        errMessage={this.state.shadeError}
                                                    /> :
                                                    <div id="nonupdate">
                                                        <label className="control-label">Shade</label>
                                                        <Select
                                                            onChange={(e) => this.dropdownValueChange(e, "shade")}
                                                            closeMenuOnSelect={true}
                                                            options={this.state.shadeOptions}
                                                            placeholder="Shade"
                                                            instanceId="shade-id"
                                                            classNamePrefix="shade"
                                                            value={this.state.shade}
                                                        />
                                                        <ErrorView message={this.state.shadeError} />
                                                    </div>
                                            }
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <TextAreaInputField errMessage={this.state.descriptionError} onChange={(ev) => this.handleChange('description', ev)} type="text" name="description" maxLength="5000" value={this.state.description} className="form-control" id="description" ariadescribedby="codeHelp" placeholder="Description" />
                                        </div>

                                        <div className="form-group required col-12 col-lg-4">
                                            <label className="control-label">
                                                Images
                                            </label>
                                            <input
                                                id="myFile"
                                                onChange={this.uploadFile}
                                                name="logo"
                                                type="file"
                                                className="form-control form-control-danger"
                                                accept=".jpg, .jpeg, .png"
                                            />
                                            <small className="text-muted d-flex">Image extension such as .jpg, .jpeg, .png allow only and size limit is 20MB.</small>
                                            <ErrorView message={this.state.documentError} />
                                            <div className="progressbar-image">
                                                {uploadPercentage > 0 && (
                                                    <ProgressBar
                                                        now={uploadPercentage}
                                                        active
                                                        label={`${uploadPercentage}%`}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                        <div className="row">
                                                {this.state.documents &&
                                                    this.state.documents.length > 0 ? (
                                                    this.state.documents.map((item, index) => {
                                                        return (
                                                            <div key={index} className="d-flex  col-lg-2">
                                                                <div className="document-img">
                                                                    <div
                                                                        onClick={() => this.removeImage(index)}
                                                                        className="close-btn"
                                                                    >
                                                                        {' '}
                                                                        <i className="fa fa-times"></i>
                                                                    </div>
                                                                    <img className='images' alt={item.large} src={uploadUrl + item.large}  />
                                                                    <div className="media-content">
                                                                    </div>
                                                                </div>
                                                         </div>
                                                        );
                                                    })
                                                ) : (
                                                    null
                                                )}
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons disable={isLoading} onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.eCatalogue} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        catalogue_create_res: state.catalogue_create_res,
        catalogue_single: state.catalogue_single,
        catalogue_update: state.catalogue_update
    };
}

const mapDispatchToProps = dispatch => ({
    catalogueCreate: data => dispatch(catalogueCreate(data)),
    productdropdownlist: data => dispatch(productdropdownlist(data)),
    cataloguesingle: data => dispatch(cataloguesingle(data)),
    catalogueupdate: (data, id) => dispatch(catalogueupdate(data, id)),
});

CatalogueAdd = connect(
    mapStateToProps,
    mapDispatchToProps
)(CatalogueAdd);

export default CatalogueAdd;