import React, { Component, lazy } from 'react';
import { connect } from 'react-redux';
import Inputfield from '../../components/inputfields';
import Buttons from '../../components/buttons';
import Links from '../../components/links';
import { routehelp } from '../../Routehelper';
import { expenseCatagoryCreate, singleExpenseCategory, updateExpenseCategory } from '../../actions';
import ExpenseCategoryDropdown from '../allowanceCatagory/expenseCategoryDropdown';
import { errorRender, successRedriect, getValue, validate, inputCheck } from '../../helpers/functions';
const CardTitle = lazy(() => import('../../components/cardTitle'));
class AllowanceSubCatagoryAdd extends Component {
    state = {
        pageType: 'add',
        itemId: '',
        name: '',
        code: '',
        expCategory: '',
        isImage: false,
        serverError: ["expenseCatagoryResponseData", "singleExpenseCategoryData", "expenseCategoryUpdateRes"],
        forRedirect: [
            { name: "expenseCatagoryResponseData", pageName: 'Expense Sub Category', pageUrl: 'allowanceSubCatagory', action: "Added" },
            { name: "expenseCategoryUpdateRes", pageName: 'Expense Sub Category', pageUrl: 'allowanceSubCatagory', action: "Updated" }
        ],
        setFields: [
            { name: "name", stateName: "name", type: "text" },
            { name: "code", stateName: "code", type: "text" },
            { name: "parentId", stateName: "expCategory", type: "select" },
            { name: "isImage", stateName: "isImage", type: "text" }
        ],
        nameError: '',
        codeError: '',
        expCategoryError: '',
        isLoading: false
    }

    componentDidMount() {
        let id = this.props.match.params.item_id;
        if (id) {
            this.setState({ pageType: "update", itemId: id })
            this.props.singleExpenseCategory(id);
        }
    }

    handleChange = (name, e) => {
        let isValid = inputCheck(name, e.target.value);

        if (isValid) {
            this.setState({ [name]: e.target.value });
        }
        // const re = /([^\s]+)/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     this.setState({ [name]: e.target.value });
        // }
    }

    checkBoxChange = (name, e) => this.setState({ [name]: e.target.checked });

    ondropdownChange = (e, t) => this.setState({ [e]: t });

    onsubmit = (e) => {

        let validationFields = [
            { name: 'name', label: "Name", value: this.state.name, hasError: false, isRequired: true, isNumber: false, onlyChar: true, maxLength: 50, type: "text", message: "", errName: "nameError" },
            { name: 'code', label: "Code", value: this.state.code, hasError: false, isRequired: true, isNumber: false, onlyChar: false, maxLength: 50, type: "text", message: "", errName: "codeError" },
            { name: 'expCategory', label: "Expense Category", value: this.state.expCategory, hasError: false, isRequired: true, isNumber: false, onlyChar: false, type: "select", message: "", errName: "expCategoryError" }
        ];

        let data = {
            name: this.state.name,
            code: this.state.code,
            parentId: this.state.expCategory && this.state.expCategory.value,
            isImage: this.state.isImage,
            type : "sub-categories",
        };

        e.preventDefault();

        let { newArr, valid } = validate(validationFields);

        newArr.map((el) => this.setState({ [el.errName]: el.message }));

        if (valid) {
            this.setState({ isLoading: true })
            if (this.state.pageType === "update") {
                this.props.updateExpenseCategory(data, this.state.itemId);
            } else {
                this.props.expenseCatagoryCreate(data);
            }
            validationFields = null;
            newArr = null;
        }

    };

    shouldComponentUpdate(nextProps) {
        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }
            this.state.serverError.forEach((el) => errorRender(nextProps, el));
            this.state.forRedirect.forEach((el) => successRedriect(nextProps, el.name, el.pageName, this.props, el.pageUrl, el.action));
            if (nextProps.singleExpenseCategoryData && nextProps.singleExpenseCategoryData.success) {
                if (nextProps.singleExpenseCategoryData.item) {
                    this.state.setFields.forEach((value) => {
                        if (value.type === "text") {
                            this.setState({ [value.stateName]: getValue(nextProps.singleExpenseCategoryData.item, value.name) })
                        } else if (value.type === "select" && getValue(nextProps.singleExpenseCategoryData, value.name)) {
                            let obj = {
                                label: nextProps.singleExpenseCategoryData[value.name].name,
                                value: nextProps.singleExpenseCategoryData[value.name]._id,
                            }
                            this.setState({ [value.stateName]: obj })
                            obj = null
                        }
                    })
                }
                nextProps.singleExpenseCategoryData.success = null;
            }
        }
        return true;
    }

    render() {
        const { pageType, isLoading } = this.state;
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <CardTitle title={pageType === "update" ? "Update Expense Sub Category" : "Add Expense Sub Category"} />
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group required col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.nameError} handleChange={this.handleChange} type="text" name="name" maxlength="100" value={this.state.name} className="form-control" id="name" ariadescribedby="codeHelp" placeholder="Name" />
                                        </div>
                                        <div className="form-group required col-12 col-lg-4">
                                            <Inputfield errMessage={this.state.codeError} handleChange={this.handleChange} maxlength="10" type="code" name="code" value={this.state.code} className="form-control" id="code" aria-describedby="codeHelp" placeholder="Code" />
                                        </div>
                                        <div className="col-md-6 form-group required col-12 col-lg-4">
                                            <ExpenseCategoryDropdown
                                                isLabel={true}
                                                ondropdownChange={(e) => this.ondropdownChange("expCategory", e)}
                                                placeholder="Search Category"
                                                value={this.state.expCategory}
                                                errMessage={this.state.expCategoryError}
                                            />
                                        </div>
                                        <div className="form-group col-12 col-lg-4">
                                            <Inputfield handleChange={this.checkBoxChange} type="checkbox" name="isImage" checked={this.state.isImage} aria-describedby="codeHelp" placeholder="Is Image" className="m-4" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-4 offset-md-3 text-center">
                            <Buttons onclick={this.onsubmit} type="submit" className="btn btn-primary mr-1" disable={isLoading} loading={isLoading} name={pageType === "update" ? "Update" : "Save"} />
                            <Links to={routehelp.allowanceSubCatagory} className="btn btn-outline-dark" name="Cancel" />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    return {
        expenseCatagoryResponseData: state.expenseCatagoryResponseData,
        singleExpenseCategoryData: state.singleExpenseCategoryData,
        expenseCategoryUpdateRes: state.expenseCategoryUpdateRes
    };
}


const mapDispatchToProps = dispatch => ({
    expenseCatagoryCreate: data => dispatch(expenseCatagoryCreate(data)),
    singleExpenseCategory: data => dispatch(singleExpenseCategory(data)),
    updateExpenseCategory: (data, id) => dispatch(updateExpenseCategory(data, id))
});

AllowanceSubCatagoryAdd = connect(
    mapStateToProps,
    mapDispatchToProps
)(AllowanceSubCatagoryAdd);

export default AllowanceSubCatagoryAdd;