import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { graphDataReq, misReportTableReq, routeOrdermanagementlist, misPdfExport, graphDataReq2, orderTemplateDropDownList } from "../../actions";
import Entries from "../../components/entries/entires";
import Pagination from "../../components/pagination/index";
import ButtonWithItag from "../../components/buttonwithTagi";
import toastr from "reactjs-toastr";
import Loader from "../../components/loader";
import { apiUrl } from "../../config";
import Userdropdown from '../userManagement/userDropdwon';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import Map from '../../components/map';
import 'react-day-picker/lib/style.css';
import "react-confirm-alert/src/react-confirm-alert.css";
import "reactjs-toastr/lib/toast.css";
import TableView from '../../components/dashboardTableView';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Select from 'react-select';

let itemsPerPage = 50;
let currentPage = 1;
let sortBy = "";
let sort = "";
let now = new Date();

class Mis extends Component {

  constructor(props) {
    super(props);
    this.state = {
      view: false,
      check: "",
      name: "",
      code: "",
      isLoading: true,
      brand: "",
      isFilter: false,
      isTableLoading: false,
      customer: [],
      customer_array: "",
      startdate: "",
      excelType: "",
      viewOrder: {},
      totQty: 0,
      series: "",
      transporter: "",
      transporterName: "",
      agent_array: [],
      agent_value: [],
      dateValueName: "",
      startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0),
      endDate: this.getDailyDate(now, 'hourly'),
      from: undefined,
      to: undefined,
      showDate: false,
      total: 0,
      mapView: false,
      isGraphShow: false,
      chartOptions: {
        chart: {
          type: 'bar'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },

        yAxis: {
          min: 0,
          title: {
            text: 'Orders Count',
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        tooltip: {
          valueSuffix: 'orders'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          // layout: 'horizontal',
          // align: 'center',
          // verticalAlign: 'bottom',
          // x: 0,
          // y: 10,
          // floating: true,
          // // borderWidth: 1,
          // backgroundColor:
          //   Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
          // // shadow: true
          reversed: true
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: [],
          title: {
            text: "Brands"
          }
        },
        series: []

      },
      chartOptions2: {
        chart: {
          type: 'column',
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Days'
          }
        },
        yAxis: {
          title: {
            text: 'No of orders',
          },
        },
        series: [
          // {
          //   name: 'New Order',
          //   data: [
          //     [Date.UTC(2023, 6, 1), 10],
          //     [Date.UTC(2023, 6, 2), 15],
          //     [Date.UTC(2023, 6, 3), 8],
          //     // Add more data points as needed...
          //   ],
          // },
          // {
          //   name: 'Confirm Order',
          //   data: [
          //     [Date.UTC(2023, 6, 1), 5],
          //     [Date.UTC(2023, 6, 2), 12],
          //     [Date.UTC(2023, 6, 3), 6],
          //     // Add more data points as needed...
          //   ],
          // },
          // Add more series as needed...
        ],
      },
      orderTemplateList: [],
      orderTemplate: null,
      monthSelectionList: [
        {
          value: "current", label: "Current"
        },
        {
          value: "prev", label: "Previous"
        }
      ],
      monthSelected: null,
      graph2Loader: false,
      webFeature: {},
      pendingC: 0,
      confirmC: 0
    };
  };

  componentDidMount() {
    this.setState({
      monthSelected: {
        value: "current", label: "Current"
      },
    });

    this.loadResult();
    this.dateSelection({ value: 'lastWeek' }, true);

    let data = {};
    data.sortBy = "createdAt";
    data.sort = "desc";
    data.skip = 0;
    data.limit = 1000000;
    this.props.orderTemplateDropDownList(data);
  }

  componentWillUnmount() {
    currentPage = 1;
    itemsPerPage = 50;
    this.setState = (state, callback) => {
      return;
    };
  }

  loadResult = (value) => {

    if (this.state.isFilter === true) {
      currentPage = 1;
    }

    let graphReq = {
      filter: {
        createdAt: {
          "from": this.state.startDate,
          "to": this.state.endDate
        }
      }
    };

    // console.log(this.state.startDate, this.state.endDate);

    if (value === "table") {
      graphReq.sortBy = sortBy;
      graphReq.sort = sort;
      graphReq.skip = (currentPage - 1) * itemsPerPage;
      graphReq.limit = itemsPerPage;
      this.props.misReportTableReq(graphReq);
      this.setState({ isFilter: false, isTableLoading: true, view: false });
    } else {
      this.props.graphDataReq(graphReq);
      if (this.state.agent_array && this.state.agent_array.length > 0) {
        graphReq.agentNames = this.state.agent_array;
      } else {
        graphReq.agentNames = [];
      }
      graphReq.sortBy = sortBy;
      graphReq.sort = sort;
      graphReq.skip = (currentPage - 1) * itemsPerPage;
      graphReq.limit = itemsPerPage;
      this.props.misReportTableReq(graphReq)
      this.setState({ isLoading: true, isFilter: false, isTableLoading: true, view: false });
    }

    // console.log(this.state.startDate, this.state.endDate);
    this.props.graphDataReq2({});
    this.setState({
      graph2Loader: true
    });
    this.props.filterUpdate(this.state.startDate, this.state.endDate, this.state.agent_array);
  }

  handleDayClick = (day) => {
    // console.log(day);
    // if (day > new Date()) {
    //   toastr.error("Future Date Cannot be selected!!", "error", {
    //     displayDuration: 5000,
    //     width: "400px",
    //   });
    // } else {
    //   const range = DateUtils.addDayToRange(day, this.state);
    if (day && day.length > 0) {
      const range = {
        from: day[0],
        to: day[1]
      }
      // console.log(range);
      this.setState(range);
    }

    // }
  };

  handleResetClick = () => this.setState({ from: undefined, to: undefined });

  handleApplyDates = () => {
    this.setState({ showDate: false, startDate: new Date(this.state.from.getFullYear(), this.state.from.getMonth(), this.state.from.getDate()), endDate: this.getDailyDate(this.state.to, 'daily') });
  };

  // setdate = (e) => this.setState({ startdate: new Date(e.target.valueAsDate) });

  ondropdownChangeagent = (e, t) => {
    let temp = [];
    let newTemp = [];
    // this.setState({ [e]: t.value });
    if (t && t !== undefined && t !== null) {
      t.forEach((item) => {
        temp.push(
          item.value,
        )
        newTemp.push({
          value: item.value,
          label: item.label
        })
        // this.setState({ customer: temparray })
        // console.log(temparray);
      })

      this.setState({ agent_array: temp, agent_value: newTemp });
    } else {
      this.setState({ agent_array: [], agent_value: [] })
    }
  };

  sortBy = (key) => {
    sortBy = key;
    if (sort === "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    this.loadResult("table");
  };

  resetfilter = () => {
    // this.state.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
    //   this.state.endDate = this.getDailyDate(now, 'hourly'),

    this.setState({
      startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
      endDate: this.getDailyDate(now, 'hourly'),
      isFilter: true,
      isLoading: false,
      isTableLoading: false,
      dateValueName: "lastWeek",
      agent_array: [], agent_value: []
    });

    // this.setState({
    //   startDate: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
    //   endDate: this.getDailyDate(now, 'hourly'),
    //   isFilter: false,
    //   isLoading: true,
    //   isTableLoading: true,
    //   dateValueName: "lastWeek",
    //   agent_array: [], agent_value: []
    // }, () => this.loadResult());

    // console.log(this.state.dateValueName);

    localStorage.setItem('set-type', JSON.stringify('lastWeek'));
    this.dateSelection({ value: 'lastWeek' }, true);
    // this.props.filterUpdate(this.state.startDate, this.state.endDate, this.state.agent_array);
  };

  toggleFilter = () => this.setState({ isFilter: !this.state.isFilter });

  handleNext = () => {
    currentPage = currentPage + 1;
    this.loadResult("table");
  };

  handlePrevious = () => {
    currentPage = currentPage - 1;
    this.loadResult("table");
  };

  handlePage = (page) => {
    currentPage = page;
    this.loadResult("table");
  };

  handleChangeiItemsPerPage = (items) => {
    currentPage = 1;
    itemsPerPage = items;
    this.loadResult("table");
  };

  getDailyDate(time, dateType) {
    return new Date(time.getFullYear(), time.getMonth(), time.getDate(), 23, 59, 59);
  };

  dateSelection = (e, flag = false) => {
    let value;
    let type = JSON.parse(localStorage.getItem('set-type'));
    if (type && flag) {
      this.setState({ dateValueName: type, showDate: false });
    } else {
      if (flag) {
        this.setState({ dateValueName: e.value, showDate: false });
        value = e.value;
      } else {
        this.setState({ dateValueName: e.target.value, showDate: false });
        value = e.target.value;
        localStorage.setItem('set-type', JSON.stringify(value));
      }
    }

    // console.log(value);

    let graphReq = {
      filter: {
        createdAt: {}
      }
    };

    const now = new Date();

    if (value === 'today') {
      this.setState({
        from: undefined,
        to: undefined,
        startDate: graphReq.filter.createdAt.from = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0),
        endDate: graphReq.filter.createdAt.to = this.getDailyDate(now, 'hourly'),
      });

    } else if (value === 'yesterday') {

      // console.log('called-yes');
      this.setState({
        from: undefined,
        to: undefined,
        startDate: graphReq.filter.createdAt.from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1),
        endDate: graphReq.filter.createdAt.to = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59)
      });

    } else if (value === 'lastWeek') {

      this.setState({
        from: undefined,
        to: undefined,
        startDate: graphReq.filter.createdAt.from = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7),
        endDate: graphReq.filter.createdAt.to = this.getDailyDate(now, 'daily')
      });

    } else if (value === 'lastMonth') {

      this.setState({
        from: undefined,
        to: undefined,
        startDate: graphReq.filter.createdAt.from = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()),
        endDate: graphReq.filter.createdAt.to = this.getDailyDate(now, 'daily'),
      });

    } else if (value === 'custom') {
      this.setState({ showDate: true });
    }

  };

  shouldComponentUpdate(nextProps) {
    // console.log(nextProps);

    if (nextProps !== this.props) {

      this.setState({
        webFeature: this.props.webFeature
      });

      // if (this.state.graph2Loader) {
      //   this.setState({ graph2Loader: loaderUpdate(nextProps, "graphDataReq2_res") });
      // }

      if (nextProps.graphData && nextProps.graphData.success === true) {
        // eslint-disable-next-line
        this.state.chartOptions.xAxis.categories = nextProps.graphData.brands;
        // eslint-disable-next-line
        this.state.chartOptions.series = nextProps.graphData.series;
        nextProps.graphData.success = null;
        this.setState({ isLoading: false, isGraphShow: true });
      };

      if (nextProps.graphDataReq2_res && nextProps.graphDataReq2_res.success === true) {
        // console.log("", nextProps.graphDataReq2_res);
        let chartOptions2 = this.state.chartOptions2;
        chartOptions2.series = nextProps.graphDataReq2_res.list || [];
        this.setState({
          chartOptions2: chartOptions2,
          pendingC: nextProps.graphDataReq2_res.pendingCount || 0,
          confirmC: nextProps.graphDataReq2_res.confirmCount || 0,
          graph2Loader: false
        }, () => {
          // console.log(this.state.chartOptions2);
        });
        nextProps.graphDataReq2_res.success = null;
      };

      if (nextProps.misTableData !== undefined) {
        if (nextProps.misTableData.success === true) {
          nextProps.misTableData.success = null;
          this.setState({ isLoading: false, isTableLoading: false });
        }
      };

      if (nextProps.misPdfExportData !== undefined) {
        if (nextProps.misPdfExportData.success === true) {
          nextProps.misPdfExportData.success = null;
          if (nextProps.misPdfExportData.item && nextProps.misPdfExportData.item.fileName) {
            window.open(apiUrl + "/uploads/" + nextProps.misPdfExportData.item.fileName);
          } else {
            toastr.error("Sorry No record Found!!!", "Error", {
              displayDuration: 5000,
            })
          }
        }
      };

      if (nextProps.orderTemplateDropDownList_res && nextProps.orderTemplateDropDownList_res.count > 0) {
        let list = nextProps.orderTemplateDropDownList_res.list.map((ele) => {
          return {
            value: ele._id,
            label: ele.name
          }
        });
        this.setState({
          orderTemplateList: list || []
        });
      };
    };

    return true;

  }

  location = (agent) => {
    let data = {}
    data.filter = { agent: agent.agentId, createdAt: { from: this.state.startDate, to: this.state.endDate } };
    this.setState({ mapView: true, agent: agent.user }, () => {
      this.props.routeOrdermanagementlist(data);
    });
  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props.name,
      activeMarker: marker,
      showingInfoWindow: true
    });
  };

  misExport = () => {
    const { startDate, endDate } = this.state;
    let data = {
      filter: {
        createdAt: {
          "from": startDate,
          "to": endDate
        }
      }
    }
    this.props.misPdfExport(data)
  }

  changeHandler = (event) => {
    this.setState({ view: !this.state.view });
    if (!event.target.checked) {
      this.loadResult("table");
    }
  }

  dropdownValueChange = (value, name) => {
    this.setState({ [name]: value }, () => {
      this.props.graphDataReq2({
        orderTemplate: this.state.orderTemplate ? this.state.orderTemplate.value : undefined,
        monthType: this.state.monthSelected ? this.state.monthSelected.value : undefined
      });
    });
    this.setState({
      graph2Loader: true
    });
  };

  resetGraph2Data = () => {
    this.setState({
      orderTemplate: null,
      monthSelected: {
        value: "current", label: "Current"
      }
    }, () => {
      this.props.graphDataReq2();
      this.setState({
        graph2Loader: true
      });
    });
  };

  render() {
    // eslint-disable-next-line
    this.state.total = 0;
    const { from, to } = this.state;
    // console.log(this.state);
    let { graphData, misTableData, routeOrder_res } = this.props;

    // if (graphData && graphData.success === true) {
    //   // eslint-disable-next-line
    //   this.state.chartOptions.xAxis.categories = graphData.brands;
    //   // eslint-disable-next-line
    //   this.state.chartOptions.series = graphData.series;
    //   // this.setState({ isLoading: false });
    //   graphData.success = null
    // }

    const totResult = misTableData !== undefined ? misTableData.count : 0;
    const pathCoordinates = [];
    const name = this.state.selectedPlace;
    // console.log(routeOrder_res);

    if (routeOrder_res && routeOrder_res.list && routeOrder_res.list.length > 0) {
      routeOrder_res.list.forEach((item) => {
        if (item.latitude && item.longitude) {
          pathCoordinates.push({ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) })
        }
      });
    }

    return (
      <React.Fragment>

        <div className="gray-2 mr-0 mb-3 d-flex justify-content-end dashboard-filter">
          <ButtonWithItag
            classNameI="fa fa-filter"
            onclick={this.toggleFilter}
            type="button"
            className="btn  btn-primary"
            data-toggle="tooltip"
            data-placement="top"
            title="Filter"
            data-original-title="Click for Filter option"
          />
        </div>

        {
          this.state.webFeature.brandVsUomG ?
            <div className="row">
              <div className="col-md-12">
                <div className="card card-shadow mb-4">
                  <div className="card-body pb-0 text-center">
                    <div className="date-filter d-flex align-items-center">
                      <p className="gray-2 mr-3 mb-0">
                        {!this.state.startDate && !this.state.endDate && 'Please select the first day.'}
                        {this.state.startDate && !this.state.endDate && 'Please select the last day.'}
                        {this.state.startDate && this.state.endDate && `Selected from ${this.state.startDate.toLocaleDateString()} to ${this.state.endDate.toLocaleDateString()}`}{' '}
                      </p>
                      <ButtonWithItag onclick={this.misExport} classNameI="fa fa-download" type="button" className="btn  btn-primary mr-2" dataplacement="top" title="Export as a file" data-original-title="Click to download all" />
                    </div>
                    <h5 className="mb-0 ">Orders</h5>
                    <p className="">{this.props.name} World</p>
                  </div>
                  <div className="my-5">
                    {this.state.isLoading ?
                      <Loader /> :
                      <div className="container">
                        {
                          this.state.isGraphShow && !this.state.isLoading && graphData && graphData.count > 0 ?
                            <Fragment>
                              {/* {console.log(this.state.chartOptions2)} */}
                              <HighchartsReact highcharts={Highcharts} options={this.state.chartOptions} />
                            </Fragment>
                            :
                            <p className="text-center">No Record Found </p>
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div> : null
        }

        {
          this.state.webFeature.orderVsDaysG ?
            <div className="row">
              <div className="col-md-12">
                <div className="card card-shadow mb-3">
                  <div className="card-body pb-0 text-center mb-0">
                    <h5 className="mb-0" Style="display:inline-block;">Order Vs Days</h5>
                  </div>
                  <div className="my-5">
                    <div className="card-body">
                      <div className="">
                        {
                          this.state.graph2Loader ? <Loader /> :
                            this.state.chartOptions2 && this.state.chartOptions2.series && this.state.chartOptions2.series.length > 0 ?
                              <Fragment>
                                {/* {console.log(this.state.orderTemplateList)} */}
                                <div className="row row-align">
                                  <div className="form-group col-12 col-lg-3">
                                    <label className="control-label">Order Template
                                    </label>
                                    <Select
                                      classNamePrefix="orderTemplate"
                                      instanceId='orderTemplate-id'
                                      name="orderTemplate"
                                      placeholder='Select Order Template'
                                      onChange={(e) => this.dropdownValueChange(e, "orderTemplate")}
                                      options={this.state.orderTemplateList}
                                      value={this.state.orderTemplate} />
                                  </div>
                                  <div className="form-group col-12 col-lg-2">
                                    {/* {console.log(this.state.monthSelected)} */}
                                    <label className="control-label">Month
                                    </label>
                                    <Select
                                      classNamePrefix="month"
                                      instanceId='month-id'
                                      name="monthSelected"
                                      placeholder='Select Month'
                                      onChange={(e) => this.dropdownValueChange(e, "monthSelected")}
                                      options={this.state.monthSelectionList}
                                      value={this.state.monthSelected} />
                                  </div>
                                  <div className="form-group col-12 col-lg-1">
                                    <label className="d-block">&nbsp;</label>
                                    <button type="button" className="btn btn-danger" onClick={() => this.resetGraph2Data()}>Reset</button>
                                  </div>
                                  <div className="form-group col-12 col-lg-3">
                                    <label class="control-label">&nbsp;</label>
                                    <div className="border border-dotted alert alert-success">
                                      <span>Ready / Pending Stock</span>
                                      <span className="font-weight-bold text-right float-right">{this.state.pendingC || 0}</span>
                                    </div>
                                  </div>
                                  <div className="form-group col-12 col-lg-3">
                                  <label class="control-label">&nbsp;</label>
                                    <div className="border border-dotted alert alert-primary">
                                      <span>Confirmed / Sent Order</span>
                                      <span className="font-weight-bold text-right float-right">{this.state.confirmC || 0}</span>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <HighchartsReact highcharts={Highcharts} options={this.state.chartOptions2} />
                                </div>
                              </Fragment>
                              : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> : null
        }

        <div className="row mb-4 ml-1 justify-content-end mr-2">
          <div className="toggle-view">
            <label>
              Change View
            </label>
            <label className="switch">
              <input type="checkbox" name="view" checked={this.state.view} onChange={this.changeHandler} />
              <span className="slider"></span>
            </label>
          </div>
        </div>

        {
          this.state.view ?
            <TableView startDate={this.state.startDate} endDate={this.state.endDate} agent_array={this.state.agent_array} />
            :
            <div className="row">
              <div className="col-md-12">
                <div className="card card-shadow mb-3">
                  <div className="card-body pb-0 text-center">
                    <h5 className="mb-0" Style="display:inline-block;">User Wise - Brand Wise - Order Matrix</h5>
                  </div>
                  <div className="my-5">
                    <div className="card-body">
                      <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                        <div className="row">
                          <div className="col-9">
                            {
                              totResult !== 0 ? (
                                <Entries
                                  itemsPerPage={itemsPerPage}
                                  handleChangeiItemsPerPage={
                                    this.handleChangeiItemsPerPage
                                  }
                                />
                              )
                                : null
                            }
                          </div>
                        </div>
                        {this.state.isTableLoading ? <Loader /> : totResult === 0 ? (
                          <p Style={{ textAlign: "center", marginTop: 10 }} className="text-center">No Record Found </p>
                        ) : (
                          <div className="row">
                            <div className="col-sm-12 display-grid">
                              <div className="table-responsive">
                                <table id="bs4-table"
                                  className="table table-bordered table-striped dataTable"
                                  cellSpacing="0">
                                  <tbody>
                                    {misTableData && misTableData.list && misTableData.list.map((item, index) => {
                                      // console.log(misTableData);
                                      return (
                                        <tr role="row" key={index}>
                                          {item && item.isSubCategory ?
                                            <th className="title-center fixed-td">{"User".toUpperCase()}</th>
                                            : <td className="title-center" Style={item.user !== "Totals" ? { fontWeight: 'normal' } :
                                              { fontWeight: 'bold' }} >{item.user}
                                            </td>}
                                          {item && item.isSubCategory ?
                                            <th className="title-center fixed-td">{"Total Orders".toUpperCase()}</th> :
                                            <td className="title-center"
                                              Style={item.user !== "Totals" ? { fontWeight: 'normal' } : { fontWeight: 'bold' }} >
                                              {item.orderCount ? item.orderCount : ""}
                                            </td>
                                          }
                                          {
                                            item && item.isSubCategory ?
                                              <th className="title-center fixed-td">{"Map".toUpperCase()}</th> :
                                              <td className="title-center">
                                                {item.agentId ? <ButtonWithItag onclick={() => this.location(item)}
                                                  datatoggle="modal" datatarget="#location-modal" type="button"
                                                  classNameI="fa fa-map-marker" className="btn btn-primary action-btn mr-1"
                                                  data-toggle="tooltip" data-placement="top"
                                                  title="" data-original-title="location" /> : null} </td>
                                          }
                                          {/* {
                                          item && item.isSubCategory ?
                                            <th className="title-center">{"Locations".toUpperCase()}</th> :
                                            item.orderAddresses && item.orderAddresses.length > 0 ?
                                              <td className="title-center">
                                                {
                                                  item.orderAddresses.map((ele, i) => {
                                                    if (ele) {
                                                      return <li key={i}>{ele || 'NA'}</li>
                                                    }
                                                  })
                                                }
                                              </td> : <td></td>
                                        } */}
                                          {item && item.columns.map((column, index) => {
                                            return item && item.isDetail ? <td Style={item.user !== "Totals" ?
                                              { fontWeight: 'normal' } :
                                              { fontWeight: 'bold' }} className="title-center"
                                              colSpan={column.colspan} key={index}>{column.amount}</td> :
                                              <th className="title-center" colSpan={column.colspan} key={index}>
                                                {column.displayName.toUpperCase()}</th>
                                          }
                                          )}
                                        </tr>
                                      )
                                    })
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                        {totResult !== undefined ? (
                          <Pagination
                            handleChangeiItemsPerPage={
                              this.handleChangeiItemsPerPage
                            }
                            handlePage={this.handlePage}
                            handleNext={this.handleNext}
                            handlePrevious={this.handlePrevious}
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            totResult={totResult}
                            key={currentPage}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
        }

        {/* <div className="card-body">
          <div className={this.state.showDate === true ? "modal fade bd-example-modal-lg show" : "modal fade bd-example-modal-lg"}
            tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
            Style={{ display: this.state.showDate ? "block" : "none" }}
            aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body">
                  <button onClick={() => this.setState({ showDate: false })} type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div>
                    <p>
                      {!from && !to && 'Please select the first day.'}
                      {from && !to && 'Please select the last day.'}
                      {from &&
                        to &&
                        `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
                    </p>
                    <div className="calender-style">
                      <DayPicker
                        className="Selectable"
                        numberOfMonths={2}
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {from && to && (
                    <div>
                      <button type="button" className="btn btn-secondary mr-2" onClick={this.handleResetClick} >Reset</button>
                      <button type="button" className="btn btn-primary" onClick={() => this.handleApplyDates()}>Apply</button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* {console.log(this.state.isFilter, this.state.showDate)} */}

        <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
          <div className="modal-header bg-primary rounded-0">
            <h6 className="filter-modal-title"> Filter</h6>
            <button
              type="button"
              onClick={this.toggleFilter}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                className="navbar-toggler right-sidebar-toggler pointer"
              >
                ×
              </span>
            </button>
          </div>
          <div className="card-body">
            <form>
              <div className="col-sm-12 col-9 mr-3">
                <div className=" form-group">
                  <Userdropdown
                    noLable={false}
                    ondropdownChange={this.ondropdownChangeagent}
                    name="agent"
                    value={this.state.agent_value}
                    className="form-control "
                    placeholder="User"
                    isMulti={true} isSearchable={true}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-9 mr-3">
                <div className="form-group mb-4">
                  <label id="choose_filter">Choose Filter</label>
                  <select
                    className="form-control"
                    name="dateValueName"
                    value={this.state.dateValueName}
                    onChange={(e) => this.dateSelection(e, false)}
                    id="dateValueName"
                  >
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="lastWeek">Last Week</option>
                    <option value="lastMonth">Last Month</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

              </div>
              <div className="col-sm-12 col-9 mr-3">
                {/* <div className=" form-group">
                  <Userdropdown
                    noLable={false}
                    ondropdownChange={this.ondropdownChangeagent}
                    name="agent"
                    value={this.state.agent_value}
                    className="form-control "
                    placeholder="User"
                    isMulti={true} isSearchable={true}
                  />
                </div>
                <div
                  id="bs4-table_filter"
                  className="dataTables_filter"
                >
                </div> */}
                {/* {console.log(this.state.dateValueName, this.state.showDate)} */}
                {this.state.dateValueName === "custom" && this.state.showDate ?
                  <div>
                    {/* <div className="calender-style filter-calendar-picker">
                      <DayPicker
                        className="Selectable"
                        numberOfMonths={2}
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick} />
                    </div> */}

                    <div className="ml-3">
                      <Calendar
                        onChange={this.handleDayClick}
                        selectRange={true}
                        value={[from, to]}
                        maxDate={new Date()}
                      />
                    </div>

                    <p className="ml-3">
                      {!from && !to && 'Please select the first day.'}
                      {from && !to && 'Please select the last day.'}
                      {from &&
                        to &&
                        `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
                    </p>

                    <div className="modal-footer">
                      {from && to && (
                        <div>
                          <button type="button" className="btn btn-outline-dark mr-2" onClick={this.handleResetClick} >Reset</button>
                          <button type="button" className="btn btn-primary" onClick={() => this.handleApplyDates()}>Apply</button>
                        </div>
                      )}
                    </div>

                  </div>
                  :
                  <div>
                    {
                      this.state.isFilter && this.state.dateValueName === 'custom' && !this.state.showDate ?
                        <p>
                          {!from && !to && 'Please select the first day.'}
                          {from && !to && 'Please select the last day.'}
                          {from &&
                            to &&
                            `Selected from ${from.toLocaleDateString()} to
                              ${to.toLocaleDateString()}`}{' '}
                        </p> : null
                    }
                  </div>
                }

              </div>

            </form>
          </div>

          {
            !this.state.showDate ?
              <div>
                <div className="col-sm-12 col-9 mr-3 text-center">
                  <button
                    onClick={this.loadResult}
                    data-dismiss="modal"
                    type="button"
                    aria-hidden="true"
                    className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1"
                  >
                    Apply
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    onClick={this.resetfilter}
                    className="btn btn-outline-dark"
                  >
                    Reset
                  </button>
                </div>
              </div> : null
          }
        </div>

        {/* {console.log(this.state.mapView)} */}
        <div className="modal fade bd-example-modal-lg"
          tabIndex="-1" role="dialog" id="location-modal"
          ria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">AGENT : {this.state.agent} ,  {this.state.startDate &&
                  this.state.endDate &&
                  `Selected from ${this.state.startDate.toLocaleDateString()} to
                ${this.state.endDate.toLocaleDateString()}`} </h5>
                <button onClick={() => { this.setState({ mapView: false }) }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" Style='cursor:pointer'>×</span>
                </button>
              </div>
              {/* {console.log(pathCoordinates)} */}
              <div className="model-body">
                <Map name={name} pathCoordinates={pathCoordinates} showingInfoWindow={this.state.showingInfoWindow}
                  activeMarker={this.state.activeMarker}
                  selectedPlace={this.state.selectedPlace} onMarkerClick={this.onMarkerClick} ordermanagement_res={routeOrder_res} />
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );

  }
}

function mapStateToProps(state) {
  return {
    graphData: state.graphData,
    misTableData: state.misTableData,
    routeOrder_res: state.routeOrder_res,
    misPdfExportData: state.misPdfExportData,
    graphDataReq2_res: state.graphDataReq2_res,
    orderTemplateDropDownList_res: state.orderTemplateDropDownList_res,
  };
}

const mapDispatchToProps = (dispatch) => ({
  graphDataReq: (data) => dispatch(graphDataReq(data)),
  misReportTableReq: (data) => dispatch(misReportTableReq(data)),
  routeOrdermanagementlist: data => dispatch(routeOrdermanagementlist(data)),
  misPdfExport: data => dispatch(misPdfExport(data)),
  graphDataReq2: (data) => dispatch(graphDataReq2(data)),
  orderTemplateDropDownList: data => dispatch(orderTemplateDropDownList(data)),
});

Mis = connect(mapStateToProps, mapDispatchToProps)(Mis);

export default Mis;
