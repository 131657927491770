import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerdropdownlist } from '../../actions';
import SearchableDropDown from '../../components/searchableDropDown';
import { optionsList } from '../../helpers/functions';
import ErrorView from '../../components/errorView';
// import useDeepCompareEffect from 'use-deep-compare-effect';

let CustomerDropDown = ({ ondropdownChange, value, placeholder, lable, filterBy, isLabel, errMessage, disable, preFixValue, dontPreLoad, isMulti = false }) => {

    const dispatch = useDispatch();
    let customerdropdownlist_res = useSelector(state => state.customerdropdownlist_res);
    let [searchList, setSearchList] = React.useState([]);
    let [isData, setIsData] = React.useState();


    React.useEffect(() => {
        let data = {};
        data.limit = 10000;
        // data = filterBy;
        data = { ...data, ...filterBy };
        if (!dontPreLoad) {
            setSearchList([]);
            dispatch(customerdropdownlist(data));
            setIsData(true);
        }
    }, [filterBy, value]);

    React.useEffect(() => {
        if (customerdropdownlist_res !== undefined && customerdropdownlist_res.list &&  customerdropdownlist_res.list.length > 0 && !dontPreLoad) {
            setSearchList(optionsList(customerdropdownlist_res.list))
        } else {
            setIsData(false);
        }
    }, [customerdropdownlist_res]);

    return (
        <div>
            {isLabel === false ? null : <label className="control-label">{lable ? lable : "Customer"}</label>}
            <SearchableDropDown
                disable={disable}
                value={value}
                noData={isData}
                onChange={ondropdownChange}
                options={searchList}
                isMulti={isMulti}
                prefixValue={preFixValue || "customer"}
                instanceId="customer-id"
                placeholder={placeholder !== undefined ? placeholder : "Customer"}
            />
            <ErrorView message={errMessage} />
        </div>
    );
}

export default CustomerDropDown;